<template>
  <v-app id="inspire">
    <v-main class="grey lighten-3">
      <keep-alive>
      <v-container>
        <v-card class="pa-5">
            <v-container>
                <v-card-title primary-title class="justify-center green--text" >
                    Lista de Alunos
                </v-card-title>
                <center><v-btn class="white--text" style="background-color: #009263;" @click="criarAluno()"><v-icon> mdi-account-plus </v-icon> Criar Aluno </v-btn></center>
                <br>
                <v-dialog v-model="csvDialog" width="70%">
                  <v-card class="pa-5">
                    <center>
                    <v-file-input show-size v-model="file" placeholder="Anexar ficheiro csv"  label="Anexar ficheiro csv"
                    prepend-icon="mdi-paperclip" style="width:70%;" color="#009263" @change="checkFile()"/> 

                    <!-- <v-col cols="12" sm="4"> -->
                    <v-checkbox outlined color="#009263" label="Com password" v-model="passaporteComPassword" hide-details/>
                    <v-checkbox outlined color="#009263" label="Enviar e-mail com passaporte" v-model="enviarPassaporteEmailCSV" hide-details/>
                    <!-- </v-col> -->

                    <v-btn class="white--text" color="#009263" type='submit' @click="postFile()"> Inserir Alunos </v-btn>
                    </center>
                  </v-card>
                </v-dialog>
                <center><v-btn class="white--text" style="background-color: #009263;" @click="csvDialog = true"><v-icon> mdi-file-delimited </v-icon> Inserir CSV </v-btn></center>
                <br>
                <v-dialog v-model="csvEmailDialog" width="70%">
                  <v-card class="pa-5">
                    <center><span>Na primeira coluna deve conter o respetivo código de utilizador do aluno ('user') que pretende alterar e o novo e-mail ('email') na coluna seguinte.
                      A primeira linha será sempre ignorada, assumindo-se que são os cabeçalhos das colunas.</span></center>
                    <br>
                    <center>
                    <v-file-input show-size v-model="emailChangeFile" placeholder="Anexar ficheiro csv"  label="Anexar ficheiro csv"
                    prepend-icon="mdi-paperclip" style="width:70%;" color="#009263" @change="checkEmailFile()"/> 

                    <v-btn class="white--text" color="#009263" type='submit' @click="postEmailFile()"> Alterar alunos </v-btn>
                    </center>
                  </v-card>
                </v-dialog>
                <center><v-btn class="white--text" style="background-color: #009263;" @click="csvEmailDialog = true"><v-icon> mdi-file-delimited </v-icon> Alterar email - CSV </v-btn></center>
                <v-combobox
                    id="escola"
                    label="Agrupamento de Escolas"
                    prepend-icon="mdi-school"
                    :return-object="true"
                    v-model="escola"
                    color="#009263"
                    :items="escolas"
                    @change="getAlunos()"
                ></v-combobox>
                <v-container v-if="ready">
                <v-text-field
                    v-model="filtrar"
                    label="Filtrar"
                    prepend-icon="mdi-magnify"
                    color="#009263"
                    single-line
                    ></v-text-field>
                    <v-data-table
                    class="elevation-1"
                    loading-text="A carregar alunos..."
                    :headers="header_alunos"
                    :items="alunos"
                    :footer-props="footer_props"
                    :search="filtrar"
                    >
                    <template v-slot:item="row">
                    <tr>
                        <td>{{row.item.user}}</td>
                        <td>{{row.item.nome}}</td>
                        <td>{{row.item.codprofessor}}</td>
                        <td>{{row.item.turma}}</td>
                        <td>
                        <!--<v-icon @click="verAluno(row.item.id)"> mdi-eye </v-icon>-->
                        <v-icon @click="editarAluno(row.item.id)" color="#009263"> mdi-pencil </v-icon>
                        <v-icon @click="apagarAluno(row.item.user)" color="#009263"> mdi-delete </v-icon>
                        </td>
                    </tr>
                    </template>
                    </v-data-table>
                </v-container>
                <v-container v-else>
                  <center><v-img :src="require('@/assets/loading.gif')" width="150px" heigth="150px"> </v-img></center>
                </v-container>
                <v-dialog v-model="dialogEditar" width="85%">
                  <v-card>
                  <EditarAluno v-if="dialogEditar" @alteracao="atualizaAlunos()" :idProp="idEditar"/>
                  </v-card>
                </v-dialog>
            </v-container>
        </v-card>
      </v-container>
      </keep-alive>
    </v-main>
  </v-app> 
</template>



<script>
import axios from "axios"
import Swal from 'sweetalert2'
var fs = require('fs')
var fastcsv = require('fast-csv')
import EditarAluno from '@/components/Alunos/EditarAluno.vue'
const h = require("@/config/hosts").hostAPI
import {Passaport} from '@/config/passport'

  export default {
    name:'Alunos',
    components:{
         EditarAluno
    },
    data(){
      return {
        token: "",
        alunos: [],
        page:1,
        csvDialog:false,
        enviarPassaporteEmailCSV: false,
        passaporteComPassword: false,
        csvEmailDialog: false,
        dialogEditar: false,
         header_alunos: [
            {text: "Username", sortable: true, value: 'user', class: 'subtitle-1'},
            {text: "Nome", value: 'nome', class: 'subtitle-1'},
            {text: "Professor", value: 'codprofessor', class: 'subtitle-1'},
            {text: "Turma", value: 'turma', class: 'subtitle-1'},
            {text: "Operações", class: 'subtitle-1'},
        ],
        footer_props: {
            "items-per-page-text": "Mostrar",
            "items-per-page-options": [50, 100, 200, -1],
            "items-per-page-all-text": "Todos"
        },
        filtrar : "",
        ready:false,
        escolas:["Todos"],
        escolasIds:[],
        escola:"Todos",
        escolaAnterior:"Todos",
        file:{},
        emailChangeFile: {},
        idEditar:-1
      }
    },
    created: async function(){
        this.token = localStorage.getItem("token")
        var responseE = await axios.get(h + "escolas")
        this.escolasIds = responseE.data
        var i
        for(i = 0; i < this.escolasIds.length; i++){
          var string = this.escolasIds[i].localidade + " - " + this.escolasIds[i].nome 
          this.escolas.push(string)
        }
        var response = await axios.get(h + "alunos?token=" + this.token)
        this.alunos = response.data

        this.ready = true
    },
    methods: {
      checkFile: async function(){
        if(this.file != undefined){
          if(this.file.type != "application/vnd.ms-excel" && this.file.type != "text/csv"){
            Swal.fire({
                  icon: 'error',
                  text: "O ficheiro tem que ser do tipo csv!",
                  confirmButtonColor: '#009263'
                })
            this.file = {}
          }
        }
      },
      checkEmailFile: async function(){
        if(this.emailChangeFile != undefined){
          if(this.emailChangeFile.type != "application/vnd.ms-excel" && this.emailChangeFile.type != "text/csv"){
            Swal.fire({
                  icon: 'error',
                  text: "O ficheiro tem que ser do tipo csv!",
                  confirmButtonColor: '#009263'
                })
            this.emailChangeFile = {}
          }
        }
      },
      postEmailFile: function(){
        if  (!this.emailChangeFile || !this.emailChangeFile.type) {
          Swal.fire({
                  icon: 'error',
                  text: "Tem que introduzir um ficheiro csv!",
                  confirmButtonColor: '#009263'
                })
          return;
        }

        let formData = new FormData();
        
        formData.append("ficheiro", this.emailChangeFile)
 
        axios.put(h + 'alunos/csv_email/?token=' + this.token,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        ).then((response) => {
           this.csvEmailDialog = false
           Swal.fire({
                  icon: 'info',
                  html: response.data.replaceAll('\n', '<br>'),
                  confirmButtonColor: '#009263'
                })
        })
      },
      postFile: function(){
        if  (!this.file || !this.file.type) {
          Swal.fire({
                  icon: 'error',
                  text: "Tem que introduzir um ficheiro csv!",
                  confirmButtonColor: '#009263'
                })
          return;
        }

        let formData = new FormData();
        
        formData.append("ficheiro", this.file);
 
        axios.post(h + 'alunos/csv/?token=' + this.token,
          formData,
          {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
          }
        ).then((response) => {
           if(response && response.data){
            const message = response.data.message;
            const infoTurmas = response.data.turmasParaGerarPassaporte;
            this.csvDialog = false;
            if(this.enviarPassaporteEmailCSV){
              this.enviaPassaportesPorEmail(infoTurmas, !!this.passaporteComPassword);
            }
            this.passaporteComPassword = false;
            this.enviarPassaporteEmailCSV = false;
            Swal.fire({
                    icon: 'info',
                    html: message,
                    confirmButtonColor: '#009263'
                  });
           }
        })
      },
      enviaPassaportesPorEmail: async function(infoTurmas, passwords){
        for(let i = 0; i < infoTurmas.length; i++){
          const infoTurma = infoTurmas[i];
          if(infoTurma){
            const bannerPath = await Passaport.getBannerPathFromProfessor(infoTurma.codprofessor);
            let doc = null;
            if(passwords){
              doc = await Passaport.getPassaporteTurmaPasswordSemGuardarDoc(infoTurma.turma, infoTurma.codprofessor, infoTurma.agrupamento.split(",")[0], bannerPath)
            }
            else{
              doc = await Passaport.getPassaporteTurmaSemGuardarDoc(infoTurma.turma, infoTurma.codprofessor, infoTurma.agrupamento.split(",")[0], bannerPath)
            }
            if(doc){
              const pdfName = "Passaporte-" + infoTurma.turma + ".pdf";
              const docOutput = doc.output();
              const base64pdf = btoa(docOutput);
              const value = {
                emailTo: infoTurma.emailProfessor,
                emailCC: '',
                subject: `Passaporte da turma atualizada ${infoTurma.turma}`,
                message: `Boa tarde colega.\nEsperamos que se encontre bem.\n\nEnviamos anexo com os passaportes da sua turma atualizada.\n\nBom trabalho.\nA equipa Hypatiamat.`
              }
              try {
                const body = {
                  files: [
                    {
                      filename: pdfName,
                      content: base64pdf,
                      encoding: 'base64'
                    }
                  ],
                  from: 'Hypatiamat',
                  ...value
                };
                await axios.post(`${h}emails/enviarBase64?token=${this.token}`, body);
              }
              catch(error) {
                console.log("falha ao enviar email");
                console.error(error);
              }
            }
          }
        }
      },
      verAluno : function(id){
          this.$router.push({name: "Ver Aluno", params: { id : id } })
      },
      editarAluno : function(id){
          //this.$router.push({name: "Editar Aluno", params: { id : id } })
          this.idEditar = id
          this.dialogEditar = true
      },
      getAlunos : async function(){
        if(this.escola){
          this.ready = false
          if(this.escola == "Todos"){
            var response = await axios.get(h + "alunos/?token=" + this.token)
            this.alunos = response.data
          } 
          else{
            var aux = this.escola.split(" - ")
            var escolaEscolhida = this.escolasIds.find(element => element.nome == aux[1])
            if(escolaEscolhida) {var response = await axios.get(h + "escolas/" + escolaEscolhida.cod + "/alunos?token=" + this.token ); this.alunos = response.data}
          }
          //if(this.escola != this.escolaAnterior) this.page = 1
          this.ready = true
        }
      },
      apagarAluno: async function(id){
        Swal.fire({
          title: 'De certeza que deseja apagar este aluno?',
          showDenyButton: true,
          confirmButtonColor: '#009263',
          confirmButtonText: `Sim`,
          denyButtonText: `Não`,
        }).then(async (result) => {
          if (result.isConfirmed) {
              var a = await axios.delete(h + "alunos/" + id + "?token=" + this.token)
              var apagado = a.data
              if(apagado.removed){
                var response = await axios.get(h + "alunos?token=" + this.token)
                this.alunos = response.data
              }
              else{
                Swal.fire({
                  icon: 'error',
                  text: apagado.message,
                  confirmButtonColor: '#009263'
                })
              }
          }
        })    
              
      },
      criarAluno: async function(){
        this.$router.push({name: "Criar Aluno"})
      },
      atualizaAlunos: async function(){
        this.dialogEditar = false
        var response = await axios.get(h + "alunos/" + this.idEditar + "/?token=" + this.token)
        var al = this.alunos.find(a => a.id == this.idEditar) 
        var index = this.alunos.indexOf(al)
        this.alunos.splice(index, 1, response.data)
      }
    }
  }
</script>